import React from 'react';

import { Filter, QueryMany } from '@directus/sdk';
import clsx from 'clsx';
import { LatLngTuple } from 'leaflet';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import activityIconSvg from '../../assets/leisure/activity.svg';
import attractionIconSvg from '../../assets/leisure/attraction.svg';
import cateringIconSvg from '../../assets/leisure/catering.svg';
import cultureIconSvg from '../../assets/leisure/culture.svg';
import hotelIconSvg from '../../assets/leisure/hotel.svg';
import localIconSvg from '../../assets/leisure/local.svg';
import { useDirectus } from '../../hooks/directus';
import {
	DirectusArticleCategory,
	DirectusEmptyCategory,
	DirectusLeisureOfferCategory,
	DirectusListItem,
	getEnumDisplayText,
} from '../../shared/models/Directus';
import LoadingIndicator from '../../shared/views/LoadingIndicator/LoadingIndicator.view';
import SideBarView from '../../shared/views/SideBar/SideBar.view';
import { RootState } from '../../store/rootReducer';
import { selectChannels, selectScreenData } from '../../store/ScreenSlice';
import { distance } from '../../Utils/Utils';
import { ListView } from './List.view';

interface ListParams {
	collectionName: string;
	channelId: string;
	categoryName?: string;
	page?: string;
	screen: string;
}

interface Props extends RouteComponentProps<ListParams> {}

export const List = (props: Props) => {
	const { channelId, categoryName, collectionName, page, screen } =
		props.match.params;

	// prepare filter

	const channels = useSelector((state: RootState) =>
		selectChannels(state.screen)
	);
	const channel = channels.find((c) => c.id === parseInt(channelId));
	const channelName = channel?.category;

	let categories = DirectusEmptyCategory;

	let sideBarTitle = 'Filtern nach:';
	if (collectionName === 'leisure') {
		categories = DirectusLeisureOfferCategory;
	} else if (collectionName === 'articles') {
		categories = DirectusArticleCategory;
	}

	if (collectionName === 'announcements') {
		sideBarTitle = 'Filtern nach:';
	}

	const screenData = useSelector((state: RootState) =>
		selectScreenData(state.screen)
	);

	const categoryNeedle = categoryName || '';
	let categoryText = categories
		? getEnumDisplayText(categories, categoryNeedle)
		: '';
	const query: QueryMany<any> = {
		fields: [],
		filter: {},
	};
	const fields: string[] = ['title', 'content', 'id', 'image.*', 'user.*'];
	let filter: Filter<any> = {
		municipalities: {
			// eslint-disable-next-line
			municipality_id: {
				_eq: screenData?.municipality.id,
			},
		},
	};
	if (collectionName === 'articles') {
		fields?.push('date', 'category');
		query['sort']?.push('-date');
		filter['released'] = {
			_eq: true,
		};
	} else if (collectionName === 'leisure') {
		fields.push('location.*');
		fields.push('location.*', 'category');
		query['sort']?.push('title');
		filter = {};
	} else if (collectionName === 'events') {
		fields.push('location.*', 'date', 'start', 'end');
		query['sort']?.push('date');
		filter['date'] = {
			_gte: '$NOW',
		};
		filter['released'] = {
			_eq: true,
		};
	} else if (collectionName === 'announcements') {
		fields.splice(fields.indexOf('content'), 1);
		fields.splice(fields.indexOf('image.*'), 1);
		fields.push('municipality.*');
		fields.push('link');
		fields.push('date');
		query['sort']?.push('-date');
		delete filter['municipalities'];
		if (categoryNeedle !== 'latest') {
			filter['municipality'] = {
				name: {
					_eq: categoryNeedle,
				},
			};
		}
	}

	query.fields = fields;
	query.filter = filter;

	if (
		['proximity', 'latest', 'next'].indexOf(categoryNeedle) === -1 &&
		channelName !== 'events' &&
		collectionName !== 'announcements'
	) {
		query['filter']['category'] = {
			_eq: categoryNeedle,
		};
	}
	const [result, error, state] = useDirectus(async (client) => {
		return await client.items<any, any>(collectionName).readMany(query);
	});

	// Exception: If the type equals announcements, use the affected municipalities as categories
	console.log(result);
	if (
		collectionName === 'announcements' &&
		result?.data &&
		Array.isArray(result.data)
	) {
		const got = (result.data as DirectusListItem[])
			.map((i) => i.municipality?.name || '')
			.filter((i) => i)
			.sort();
		const uniqueOnly: { [id: string]: string } = {};

		uniqueOnly['latest'] = 'Neueste';
		got.forEach((i) => {
			const keys = Object.keys(uniqueOnly);
			if (i && keys.indexOf(i) === -1) {
				uniqueOnly[i] = i;
			}
		});
		categories = uniqueOnly;
		categoryText = categoryNeedle === 'latest' ? 'Neueste' : categoryNeedle;
	}

	const sidebar = (
		<SideBarView
			title={sideBarTitle}
			sideBarContents={Object.values(categories)}
			currentlySelected={categoryName}
			format={(e: any, i: number, currentlySelected?: string) => {
				const active = Object.keys(categories)[i] === currentlySelected;
				const key = Object.keys(categories)[i];
				const listsWithIcons = ['leisure'];
				const leisureIconMap = {
					proximity: localIconSvg,
					attraction: attractionIconSvg,
					activity: activityIconSvg,
					culture: cultureIconSvg,
					catering: cateringIconSvg,
					hotels: hotelIconSvg,
				};
				const iconIndex = Object.keys(leisureIconMap).indexOf(key);
				const icon =
					listsWithIcons.indexOf(collectionName) !== -1 && iconIndex !== -1 ? (
						<img
							alt={collectionName}
							src={Object.values(leisureIconMap)[iconIndex]}
						/>
					) : null;

				return (
					<a
						key={i}
						href={
							'/' +
							screen +
							'/list/' +
							collectionName +
							'/' +
							channelId +
							'/' +
							key
						}
						className={clsx({
							active,
						})}
					>
						{icon && <span className="sidebar-item-inner-icon">{icon}</span>}
						<span
							className={clsx({
								'sidebar-item-inner': true,
								'selected-item': active,
							})}
						>
							{e}
						</span>
					</a>
				);
			}}
		/>
	);
	if (
		state === 'success' &&
		result &&
		result.data &&
		Array.isArray(result.data)
	) {
		const items = result.data as DirectusListItem[];
		if (items.length === 0) {
			return (
				<>
					<div className="page-404">
						<div>
							<h1>Keine Beiträge vorhanden</h1>
							<p>
								In der Kategorie {categoryText} sind aktuell keine Beiträge
								vorhanden
							</p>
						</div>
					</div>
					{sidebar}
				</>
			);
		}
		const lat = screenData?.location.coords.lat || 1;
		const lon = screenData?.location.coords.lon || 1;
		/* Additional filter, sorting methods based on user stories */
		if (
			categoryText === DirectusLeisureOfferCategory.proximity &&
			channelName === 'leisure'
		) {
			items.sort((a, b) => {
				const screenLatLngTuple: LatLngTuple = [lat, lon];
				return (
					distance(
						[a.location?.coords.lat, a.location?.coords.lon],
						screenLatLngTuple
					) -
					distance(
						[b.location?.coords.lat, b.location?.coords.lon],
						screenLatLngTuple
					)
				);
			});
		}
		return (
			<>
				<ListView
					screen={screen}
					categoryName={categoryName}
					channelName={channelName}
					channelId={parseInt(channelId)}
					items={items}
					page={page}
					collectionName={collectionName}
					categoryText={categoryText}
					screenData={screenData}
				/>
				{collectionName !== 'events' && sidebar}
			</>
		);
	}

	if (state === 'success' && !result) {
		throw Error(error?.message);
	}
	return (
		<div className="page-spinner">
			<LoadingIndicator />
		</div>
	);
};
